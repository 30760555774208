import React from 'react';
import './App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faGithub, faDiscord, faXTwitter } from '@fortawesome/free-brands-svg-icons';
import { faGithub, faDiscord } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

function App() {
  return (
    <div className="App">
      <div className="Boner">
        <h1>#BONERSAURUS</h1>
      </div>
      <div className="Icons">
        <a href="https://github.com/bonersaurus" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faGithub} size="2x" />
        </a>
        <a href="https://discord.com/invite/pwYQbeAZqK" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faDiscord} size="2x" />
        </a>
        {/* <a href="https://twitter.com/yourusername" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faXTwitter} size="2x" />
        </a> */}
        <a href="mailto:boner@bonersaur.us" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faEnvelope} size="2x" />
        </a>
      </div>
    </div>
  );
}

export default App;
